import { useRouter } from "next/router";
import React, { useRef, useState } from "react";
import { useCallback } from "react";
import { useMemo } from "react";
import styles from "../styles/search-bar.module.css";
import SearchSuggestions, { Suggestion, SuggestionsRef } from "./search-suggestions";
import { debounce, memoize, isEmpty as _isEmpty } from "lodash-es";
import searchArticles from "../lib/search-articles";
import formatDate from "../lib/format-date";
import loadCategories, { Section } from "../lib/load-categories";
import loadTrendingArticles, { Article } from "../lib/load-trending-articles";
import trackSessionEvent, { getSessionDetailsFromLocalStorage } from "../lib/hc-session";
import { AppSettings } from "../lib/get-app-settings";

export type SearchBarProps = {
  baseUrl: string;
  companySlug: string;
  className?: string;
  title?: string;
  placeholder?: string;
  defaultValue?: string;
  appSettings: AppSettings;
};

export default function SearchBar({
  baseUrl,
  companySlug,
  className,
  title,
  placeholder,
  defaultValue,
  appSettings,
}: SearchBarProps) {
  const router = useRouter();
  const pageUrl = router.asPath;
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const query = inputRef.current?.value.trim();
    if (!query) return;
    router.push(`${baseUrl}/search?q=${query}`);
  };

  const [suggestionsTitle, setSuggestionsTitle] = useState("");
  const [suggestions, setSuggestions] = useState<Suggestion[] | null>(null);

  const trendingArticlesPromiseRef = useRef<Promise<Article[]> | null>(null);
  const categoriesMapPromiseRef = useRef<Promise<Record<string, Section>> | null>(null);

  const memoizedSearch = useMemo<(query: string) => Promise<Article[]>>(
    () =>
      memoize((query: string) => {
        const hcSessionDetails = getSessionDetailsFromLocalStorage(appSettings);
        return searchArticles(companySlug, query, "", 10, 0, undefined).then((data) => {
          if (hcSessionDetails) {
            const _event = data.length ? "SEARCHED" : "SEARCHED_NO_RESULTS";
            trackSessionEvent(_event, hcSessionDetails, pageUrl, data, [], {
              query,
            });
          }
          return data;
        });
      }),
    [appSettings, companySlug, pageUrl]
  );

  const handleChange = useMemo(
    () =>
      debounce(() => {
        const input = inputRef.current;
        if (!input) return;

        categoriesMapPromiseRef.current =
          categoriesMapPromiseRef.current ||
          loadCategories(companySlug, false, undefined).then((categories = []) =>
            categories.reduce<Record<string, Section>>(
              (map, category) => Object.assign(map, { [category.id]: category }),
              {}
            )
          );

        categoriesMapPromiseRef.current.then((map) => {
          const query = input.value;
          (!query
            ? (trendingArticlesPromiseRef.current =
                trendingArticlesPromiseRef.current || loadTrendingArticles(companySlug, undefined))
            : memoizedSearch(query)
          )
            .then(
              (results) =>
                results &&
                results.map<Suggestion>((article) => {
                  const category = map[article.categoryId];
                  const _url = _isEmpty(query)
                    ? `${baseUrl}/articles/${article.slug}`
                    : `${baseUrl}/articles/${article.slug}?SEARCHED_EVENT=${query}`;
                  return {
                    url: _url,
                    name: article.title,
                    categoryName: category.title,
                    parentCategoryName: category.parentId && map[category.parentId].title,
                    icon: category.icon,
                    publishDate: formatDate(article.lastPublishedAt),
                  };
                })
            )
            .then(setSuggestions)
            .then(() => {
              setSuggestionsTitle(query ? "Search results" : "Trending articles");
            });
        });
      }, 700),
    [setSuggestions, baseUrl, companySlug, memoizedSearch]
  );

  const [focused, setFocused] = useState(false);

  const changeFocus = useMemo(() => debounce(setFocused, 10), [setFocused]);

  const handleFocus = useMemo(
    () =>
      debounce(() => {
        handleChange();
        changeFocus(true);
      }, 5),
    [handleChange, changeFocus]
  );

  const handleBlur = useCallback(() => changeFocus(false), [changeFocus]);

  const handleClose = useCallback(() => changeFocus(false), [changeFocus]);

  const suggestionsRef = useRef<SuggestionsRef>(null);

  const handleKeyDown = useCallback<React.KeyboardEventHandler<HTMLFormElement>>((event) => {
    if (!suggestionsRef.current) return;
    if (event.key === "ArrowDown") {
      event.preventDefault();
      if (!suggestionsRef.current.next()) inputRef.current?.focus();
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      if (!suggestionsRef.current.prev()) inputRef.current?.focus();
    } else if (event.key === "Escape") {
      event.preventDefault();
      (document.activeElement as HTMLElement)?.blur();
    }
  }, []);

  return (
    <form
      className={`${className || ""} ${styles.searchBar} ${focused ? styles.focusedSearchBar : ""}`}
      onKeyDown={handleKeyDown}
      onSubmit={handleSubmit}
      itemProp="potentialAction"
      itemScope
      itemType="http://schema.org/SearchAction"
    >
      <meta itemProp="target" content={`${baseUrl}/search?q={search_term_string}`} />
      <meta itemProp="query-input" content="required name=search_term_string" />
      <label>
        {title && (
          <span className={styles.searchBarTitle} lang="en">
            {title}
          </span>
        )}
        <input
          ref={inputRef}
          className={styles.searchBarInput}
          type="search"
          name="query"
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          lang="en"
        />
      </label>
      <SearchSuggestions
        ref={suggestionsRef}
        visible={!!suggestions && focused}
        title={suggestionsTitle}
        suggestions={suggestions || []}
        className={styles.searchBarSuggestions}
        onClose={handleClose}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <input type="submit" style={{ display: "none" }} />
    </form>
  );
}
