import Script from "next/script";

const BUNDLE_LINK = process.env.NEXT_PUBLIC_BUNDLE_LINK;

export type AtlasSnippetProps = {
  appId: string;
  chatting?: boolean;
  recording?: boolean;
};

export default function AtlasSnippet({
  appId,
  chatting,
  recording,
}: AtlasSnippetProps) {
  if (!chatting && !recording) return null;
  return (
    <Script id="script-atlas-snippet">{`
        (()=>{"use strict";const t={appId:"${appId}",v:2,autorun:{recording:{enabled:${JSON.stringify(
      !!recording
    )}},chat:{enabled:${JSON.stringify(
      !!chatting
    )}}},q:[],start:function(o){this.autorun=o||!0},identify:function(t){this.q.push(["identify",t])}};window.Atlas=t;const e=document.createElement("script");e.async=!0,e.src="${BUNDLE_LINK}";const s=document.getElementsByTagName("script")[0];s.parentNode?.insertBefore(e,s)})();
    `}</Script>
  );
}
