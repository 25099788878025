import React, { useMemo } from "react";
import cn from "classnames";
import Head from "next/head";
import styles from "../styles/layout.module.css";
import Link from "next/link";
import Image from "next/image";
import SearchBar from "./search-bar";
import { AppSettings } from "../lib/get-app-settings";
import isDarkColor from "../lib/is-dark-color";
import AtlasSnippet from "./atlas-snippet";
import getBaseUrl from "../lib/get-base-url";
import { useRouter } from "next/router";

export type LayoutProps = {
  className?: string;
  pageTitle?: string;
  isCustomPageTitle?: boolean;
  articleLocale?: string | null;
  appSettings: AppSettings;
  pageDescription?: string;
  children: React.ReactNode;
  searchValue?: string;
  hasTitle?: boolean;
  canonicalUrl?: string | null;
  seoImageUrl?: string;
  hideSearch?: boolean;
  header?: React.ReactNode;
  noContentPadding?: boolean;
};

const customerPortalPath = "/customer-portal";

export default function Layout({
  className,
  pageTitle,
  isCustomPageTitle,
  articleLocale,
  appSettings,
  pageDescription,
  children,
  searchValue,
  hasTitle,
  canonicalUrl,
  seoImageUrl,
  hideSearch = false,
  header,
  noContentPadding,
}: LayoutProps) {
  const hcSuffix = appSettings.title || appSettings.name;
  pageDescription = pageDescription
    ? pageDescription
    : `Knowledge base of ${hcSuffix}`;

  const [primaryInvertedColor, secondaryInvertedColor] = useMemo(() => {
    const light = (opacity = 1) => `rgba(255, 255, 255, ${opacity})`;
    const dark = (opacity = 1) => `rgba(0, 0, 0, ${opacity})`;
    return [
      isDarkColor(appSettings.primaryColor) ? light : dark,
      isDarkColor(appSettings.secondaryColor) ? light : dark,
    ];
  }, [appSettings]);

  const baseUrl = useMemo(() => getBaseUrl(appSettings), [appSettings]);
  const router = useRouter();
  const isCustomerPortalPage = useMemo(
    () => router.pathname.includes(customerPortalPath),
    [router.pathname]
  );

  const pageTitleSuffix = `${
    appSettings.pageTitle || appSettings.title || appSettings.name
  }`;
  const normalizedPageTitle = isCustomPageTitle
    ? pageTitle
    : [pageTitle, pageTitleSuffix].filter(Boolean).join(" | ");

  const isArticle = appSettings.canonicalPageUrl.includes("/articles/");

  const ogImageContent = seoImageUrl || appSettings.logoUrl;

  return (
    <div
      className={`${styles.container} ${className || ""}`}
      itemScope
      itemType="http://schema.org/WebSite"
    >
      <meta itemProp="name" content={pageTitle} />
      {appSettings.logoUrl && (
        // While it is not displayed on the website it can be parsed by search engines to show site image
        <a href={baseUrl} itemProp="url" style={{ display: "none" }}>
          <Image
            src={appSettings.logoUrl}
            data-original={appSettings.logoUrl}
            itemProp="image"
            alt={pageTitle || ""}
            width={appSettings.logoWidth || 256}
            height={appSettings.logoHeight || 256}
          />
        </a>
      )}

      <Head>
        <title>{normalizedPageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link
          rel="canonical"
          href={canonicalUrl || appSettings.canonicalPageUrl}
        />

        {appSettings.faviconUrl ? (
          <link rel="icon" href={appSettings.faviconUrl} />
        ) : (
          <>
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/apple-touch-icon.png"
            />
            <link rel="icon" href="/favicon.ico" />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/favicon-16x16.png"
            />
          </>
        )}
        <meta name="theme-color" content="#ffffff" />

        {appSettings.hcGoogleSiteVerificationCode && (
          <meta
            name="google-site-verification"
            content={appSettings.hcGoogleSiteVerificationCode}
          />
        )}

        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        {ogImageContent && (
          <meta property="og:image" content={ogImageContent} />
        )}
        {appSettings.pageTitle ? (
          <meta property="og:site_name" content={appSettings.pageTitle} />
        ) : null}
        <meta property="og:url" content={appSettings.canonicalPageUrl} />
        {isArticle && <meta property="og:type" content="article" />}
        {articleLocale && <meta property="og:locale" content={articleLocale} />}

        <style>{`
          :root {
            --primaryColor: ${appSettings.primaryColor};
            --primaryInvertedColor: ${primaryInvertedColor()};
            --primaryInverted95Color: ${primaryInvertedColor(0.95)};
            --primaryInverted55Color: ${primaryInvertedColor(0.55)};
            --primaryInverted50Color: ${primaryInvertedColor(0.5)};
            --primaryInverted24Color: ${primaryInvertedColor(0.24)};
            --secondaryColor: ${appSettings.secondaryColor};
            --secondaryInvertedColor: ${secondaryInvertedColor()};
            --secondaryInverted85Color: ${secondaryInvertedColor(0.85)};
            --secondaryInverted55Color: ${secondaryInvertedColor(0.55)};
            --secondaryInverted30Color: ${secondaryInvertedColor(0.3)};
          }
        `}</style>
      </Head>

      <AtlasSnippet
        appId={appSettings.appId}
        chatting={appSettings.chatting}
        recording={appSettings.sessionRecording}
      />

      <header className={styles.header}>
        <div className={`${styles.content} ${styles.headerContent}`}>
          <Link href={baseUrl} className={styles.headerTitleLink}>
            {appSettings.logoUrl && (
              <Image
                className={styles.headerLogo}
                src={appSettings.logoUrl}
                alt={appSettings.name}
                width={800}
                height={80}
              />
            )}
            {hasTitle ? (
              <h1 className={styles.headerTitle}>{appSettings.title}</h1>
            ) : (
              <span className={styles.headerTitle}>{appSettings.title}</span>
            )}
          </Link>
          {appSettings.enableSupportPortal && (
            <Link
              href={`${baseUrl}${
                isCustomerPortalPage ? "" : customerPortalPath
              }`}
              className={styles.headerLink}
            >
              {isCustomerPortalPage ? "Help Center" : "Customer Portal"}
            </Link>
          )}
          {appSettings.links?.map((link) => (
            <Link
              key={link.id}
              href={link.url}
              target="_blank"
              className={styles.headerLink}
            >
              {link.label}
              {link.icon && (
                <big className={styles.headerLinkIcon}>{link.icon}</big>
              )}
            </Link>
          ))}
          <div className={styles.headerSearch}>
            {!hideSearch && (
              <SearchBar
                appSettings={appSettings}
                baseUrl={baseUrl}
                companySlug={appSettings.slug}
                className={styles.headerSearchBar}
                title="What can we help you find?"
                placeholder={`Search for articles...`}
                defaultValue={searchValue}
              />
            )}
            {hideSearch && header ? header : undefined}
          </div>
        </div>
      </header>
      <main className={cn(!noContentPadding && styles.main)}>
        <div className={styles.content}>{children}</div>
      </main>
    </div>
  );
}
