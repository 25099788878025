import http, { Query, Method } from "./http";
import Cookies from "cookies";
import { GetServerSidePropsContext, PreviewData } from "next";
import { ParsedUrlQuery } from "querystring";

export type { Query, Method };

const defaultParseResponse = <APIResponse>(
  response: Response
): Promise<APIResponse> =>
  response
    .text()
    .then((text) => {
      if (!text) return null;
      try {
        return JSON.parse(text);
      } catch (error) {
        return null;
      }
    })
    .then((result) => {
      if (response.status < 400) return result;
      return Promise.reject(result);
    });

export type Props<APIResponse, Body> = {
  path: string;
  query?: Query;
  method?: Method;
  headers?: HeadersInit;
  body?: Body;
  parseResponse?: (
    response: Response,
    defaultParser: (response: Response) => Promise<APIResponse>
  ) => APIResponse;
  context?: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>;
};

const buildBasePath = (path: string) => {
  if (path.startsWith("http")) return path;

  const base = process.env.NEXT_PUBLIC_API_BASE_URL;
  const _path = path.startsWith("/") ? path : `/${path}`;
  return `${base}${_path}`;
};

const cookieFromContext = (
  context: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>
) => {
  const { req, res } = context;
  const cookie = new Cookies(req, res);
  return cookie.request.headers.cookie || "";
};

export default function api<
  Response = Record<string, unknown>,
  Body = Record<string, unknown>
>({
  method,
  path,
  query,
  body,
  headers,
  parseResponse,
  context,
}: Props<Response, Body>): Promise<Response> {
  const cookie = context && cookieFromContext(context);

  return http({
    url: buildBasePath(path),
    query,
    method,
    headers: {
      "Content-Type": "application/json",
      ...headers,
      ...(cookie && { cookie }),
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (parseResponse) return parseResponse(response, defaultParseResponse);
      return defaultParseResponse<Response>(response);
    })
    .catch((error) => {
      if (!context) return Promise.reject(error);

      const { res } = context;

      switch (error.detail) {
        case "Unauthorized":
          res.statusCode = 401;
          res.writeHead(302, {
            Location: "/401",
          });
          res.end();
          throw new UnauthorizedError(error.message);
        case "Forbidden":
          res.statusCode = 403;
          res.writeHead(302, {
            Location: "/403",
          });
          res.end();
          throw new ForbiddenError(error.message);
        default:
          return Promise.reject(error);
      }
    });
}

export class UnauthorizedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "Unauthorized";
  }
}

export class ForbiddenError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "Forbidden";
  }
}

export class NotFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "NotFound";
  }
}
